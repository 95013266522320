<template>
    <div id="wrapper" class="mercato" style="width: 100%; height: 100%;"> 
      <mct-topo>
        <mct-menu @setiframe="setiframe"></mct-menu>
      </mct-topo>
      <div class="container border" style="height: 100%;">
    <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item" v-for="(tab, index) in lista" :key='index'>
      <a class="nav-link" data-toggle="tab" href="" @click.prevent.stop="pagIndex = index" 
        :aria-controls="tab.name" :class="{'active': index == pagIndex}" role="tab">{{tab.title}}
        <i class="fa fa-times" aria-hidden="true"  @click.prevent.stop="remiframe(index)"></i>
        <!--<button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>-->
      </a>
    </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content" style="width: 100%; height: 100%;">
      <div class="tab-pane" role="tabpanel" :class="{'active': index == pagIndex}" :id="tab.name" 
        v-for="(tab, index) in lista" :key='index' style="width: 100%; height: 100%;">
        <iframe :src="tab.component" ref="frame" load="true" width="100%" height="100%" style="border: 0;"></iframe>
      </div>
    </div>    
      </div>
    </div>
</template>

<script>
import MctTopo from './comum/MctTopo.vue'
import MctMenu from './comum/MctMenu.vue'

export default {
  components: {
    'MctTopo': MctTopo,
    'MctMenu': MctMenu,
  },
  data () {
    return {
				listaTabs: [],
				lista: [],
				pagIndex: 0
      }
	},
	
	methods: {
		setiframe (pagina) {
			//console.log(pagina);
			if (pagina.menu_pagina != ''){
				this.listaTabs.push({
					name: pagina.menu_pagina.replace('/',''),
					title: pagina.menu_descricao,
					component: pagina.menu_pagina
					});
				this.activeiframe(pagina.menu_pagina.replace('/',''));	
			}
		},
		remiframe (index) {
			this.listaTabs.splice(index, 1);
		},
		activeiframe (index) {
			for (var i in this.listaTabs) {
				var key = this.listaTabs[i];
				if (key.name == index){
					this.pagIndex = i;
				}
      }
		}
	},
	watch: {
        listaTabs () {
					this.lista = this.listaTabs
		}
	}


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
